<template>
  <div class="d-inline">
    <div class="ma-0 pa-0 d-inline">
      <slot>
        <b-button
          v-if="edit"
          variant="success"
          title="Edit Question"
          @click="addeditquestion = true"
          ><b-icon icon="pencil-square"></b-icon
        ></b-button>
        <b-button v-else variant="success" block @click="addeditquestion = true"
          ><b-icon icon="plus"></b-icon>Add Question</b-button
        >
      </slot>
    </div>

    <b-modal
      v-model="addeditquestion"
      title="Add/Edit Question"
      :no-close-on-backdrop="true"
      @ok="saveQuestion"
      @hidden="clearForm()"
      @show="init()"
      ok-variant="primary"
      size="xl"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              id="question-group"
              label="Question:"
              label-for="question"
            >
              <b-form-input
                id="question"
                v-model="form.question"
                placeholder="Question text"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-checkbox
              id="questioninactive"
              v-model="form.setinactive"
              name="questioninactive"
              :value="1"
              :unchecked-value="0"
            >
              Set as Inactive
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>

      <b-row style="margin-top: 20px">
        <b-col cols="12">
          <fieldset>
            <legend>Answers</legend>
            <HelpAnswerModal
              id="addmodal"
              @refresh="loadAnswers"
              :question="form"
              :roles="roles"
            ></HelpAnswerModal>
            <b-alert
              v-if="form.answers.length == 0"
              variant="warning"
              class="text-center text-danger"
              show
              >No Answers Found</b-alert
            >
            <table
              v-if="form.answers.length > 0"
              class="table table-striped"
              style="background-color: white"
            >
              <thead>
                <th>ID</th>
                <th>Display</th>
                <th class="text-left">Answer</th>
                <th class="text-left">Type</th>
                <th class="text-left">Visible To</th>
                <th>Active</th>
                <th></th>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in form.answers" :key="idx">
                  <td>{{ row.id }}</td>
                  <td>{{ row.display }}</td>
                  <!-- <td class="text-left"><div v-html="row.content"></div></td> -->
                  <td class="text-left">{{ row.content | truncate }}</td>
                  <td class="text-left">{{ row.answertype }}</td>
                  <td class="text-left">{{ row.rolenames }}</td>
                  <td>{{ row.active }}</td>
                  <td class="text-right">
                    <b-button-group>
                      <HelpAnswerModal
                        id="editmodal"
                        @refresh="loadAnswers"
                        :question="form"
                        :answer="row"
                        :roles="roles"
                        edit
                      ></HelpAnswerModal>
                      <b-button
                        variant="danger"
                        title="Remove Answer"
                        @click="removeAnswer(row)"
                        ><b-icon icon="x-square"></b-icon
                      ></b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </b-col>
      </b-row>

      <template #modal-ok> <b-icon icon="check"></b-icon> Save </template>
      <template #modal-cancel> <b-icon icon="x"></b-icon> Cancel </template>
    </b-modal>
  </div>
</template>
<script>
import HelpService from '@/services/HelpService'
import HelpAnswerModal from '@/components/admin/HelpAnswerModal'

export default {
  props: {
    question: {
      type: Object,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array,
      default: () => { return [] }
    }
  },

  data () {
    return {
      addeditquestion: false,
      form: {
        id: null,
        setinactive: 0,
        question: null,
        answers: []
      },
      loading: false
    }
  },

  components: {
    HelpAnswerModal
  },

  mounted () {
    this.init()
  },
  watch: {
    'question.id': function (n) {
      this.init()
    }
  },
  filters: {
    truncate: function (text) {
      const stop = 200
      const clamp = '...'
      if (text !== null && typeof text !== 'undefined') {
        return text.replace(/(<([^>]+)>)/gi, '').slice(0, stop) + (stop < text.length ? clamp || '...' : '')
      }
      return ''
    }
  },

  methods: {
    init () {
      this.form.id = null
      this.form.setinactive = 0
      this.form.question = null
      this.form.answers = []
      if (this.question) {
        this.form.id = this.question.id
        this.form.question = this.question.question
        this.form.answers = this.question.answers
        this.form.setinactive = (+this.question.active === 1 ? 0 : 1)
      }
    },

    loadAnswers (questionid) {
      this.loading = true
      this.$updateloading(1)
      let id = 0
      if (questionid > 0) {
        id = questionid
      } else {
        id = this.form.id
      }
      if (id > 0) {
        const formData = new FormData()
        formData.append('id', id)
        formData.append('admin', 1)
        HelpService.loadAnswers(formData).then(
          (response) => {
            this.form.answers = response.data.info.answers
            this.form.id = response.data.info.questionid
            // this.$aimNotify.notify(response)
          }
        ).finally(() => {
          this.$updateloading(-1)
          this.loading = false
        })
      }
    },

    saveQuestion (bvEvent) {
      bvEvent.preventDefault()
      if (this.form.question === null || this.form.question.length === 0) {
        this.$bvModal.msgBoxOk('Question is invalid.')
        return
      }
      this.loading = true
      this.$updateloading(1)
      HelpService.saveQuestion(this.form).then(
        (response) => {
          this.form.id = response.data.info
          // this.$aimNotify.notify(response)

          this.$nextTick(() => {
            // this.$bvModal.hide('addeditquestion')
            this.$emit('refresh')
          })
        }
      ).finally(() => {
        this.$updateloading(-1)
        this.loading = false
      })
    },

    clearForm () {
      this.form.id = null
      this.form.setinactive = 0
      this.form.question = null
      this.form.answers = []
    },

    removeAnswer (answer) {
      this.$bvModal.msgBoxConfirm('Are you sure you wish to remove this answer?').then(
        val => {
          if (val) {
            this.loading = true
            this.$updateloading(1)
            HelpService.removeAnswer(answer).then(
              (response) => {
                this.loadAnswers()
                this.$aimNotify.notify(response)
              }
            ).finally(() => {
              this.$updateloading(-1)
              this.loading = false
            })
          }
        }
      )
    }

  }

}
</script>
