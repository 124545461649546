<template>
  <div class="d-inline">
    <div class="ma-0 pa-0 d-inline">
      <slot>
        <b-button
          v-if="edit"
          variant="success"
          title="Edit Answer"
          @click="addeditanswer = true"
          ><b-icon icon="pencil-square"></b-icon
        ></b-button>
        <b-button v-else variant="success" block @click="checkQuestion()"
          ><b-icon icon="plus"></b-icon>Add Answer</b-button
        >
      </slot>
    </div>

    <b-modal
      v-model="addeditanswer"
      title="Add/Edit Answer"
      :no-close-on-backdrop="true"
      :no-enforce-focus="true"
      @ok="saveAnswer"
      @hidden="defaultModal()"
      @show="defaultModal()"
      ok-variant="primary"
      size="xl"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group id="text-group" label="Text:">
              <div>
                <editor
                  id="editor"
                  :key="1"
                  api-key="83u69vpf23jbsp27k3ufq78a7fp4jxwdd5xhrtbkhpueukuu"
                  v-model="form.answer.content"
                  :init="tinymceConfig"
                ></editor>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-checkbox
              id="answerinactive"
              v-model="form.setinactive"
              name="answerinactive"
              :value="1"
              :unchecked-value="0"
            >
              Set as Inactive
            </b-form-checkbox>
          </b-col>
          <b-col cols="4">
            <b-form-group id="visible-group" label="Visible to Roles:">
              <b-form-checkbox-group
                id="visible-checkbox-group"
                v-model="form.roles"
                name="roles"
              >
                <b-form-checkbox
                  v-for="(role, idx) in roles"
                  :key="idx"
                  :value="role.id"
                  >{{ role.name }}</b-form-checkbox
                >
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col cols="4" class="text-right">
            <b-form-checkbox
              id="setdefault"
              v-model="form.setdefault"
              name="setdefault"
              :value="1"
              :unchecked-value="0"
            >
              Set as Default
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group id="file-group" label="File Upload:">
              <b-form-file
                v-model="form.file1"
                :state="Boolean(form.file1)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                multiple
              ></b-form-file>
              <div class="mt-3">
                Selected file: {{ form.file1 ? form.file1.name : "" }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="form.answer.answertype == 'Help Screenshot'">
          <b-col cols="12" class="text-left">
            <img class="view-img" :src="form.answer.url" />
          </b-col>
        </b-row>

        <b-row v-if="form.answer.answertype == 'Help Video'">
          <b-col cols="12" class="text-left">
            <div style="width: 300px !important">
              <b-embed type="video" controls allowfullscreen>
                <source :src="form.answer.url" type="video/mp4" />
              </b-embed>
            </div>
          </b-col>
        </b-row>

        <b-form-group id="display-group" label="Display Order:">
          <b-input
            v-model="form.answer.display"
            type="number"
            min="0"
            step="1"
          ></b-input>
        </b-form-group>
      </b-form>

      <template #modal-ok> <b-icon icon="check"></b-icon> Save </template>
      <template #modal-cancel> <b-icon icon="x"></b-icon> Cancel </template>
    </b-modal>
  </div>
</template>

<script>
import HelpService from '@/services/HelpService'
import Editor from '@tinymce/tinymce-vue'

export default {
  props: {
    answer: {
      type: Object,
      default: null
    },
    question: {
      type: Object,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array,
      default: () => { return [] }
    }
  },

  components: {
    editor: Editor
  },

  data () {
    return {
      addeditanswer: false,
      loading: false,
      form: {
        id: null,
        question: null,
        setinactive: 0,
        setdefault: 0,
        roles: [],
        answer: {
          document_id: null,
          answertype: null,
          url: null,
          content: null,
          display: null
        },
        file1: null
      },

      tinymceConfig: {
        height: 300,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
      }
    }
  },

  mounted () {
    this.defaultModal()
  },

  methods: {
    checkQuestion () {
      if (this.question.id > 0) {
        this.addeditanswer = true
      } else {
        this.$bvModal.msgBoxOk('Please save your question before adding a new answer.')
      }
    },

    defaultModal () {
      this.loading = true
      this.$updateloading(1)
      this.form = {
        id: null,
        question: null,
        setinactive: 0,
        setdefault: 0,
        roles: [],
        answer: {
          document_id: null,
          answertype: null,
          url: null,
          content: null,
          display: null
        },
        file1: null
      }
      if (this.answer) {
        this.form.id = this.answer.id
        this.form.answer.document_id = this.answer.document_id
        this.form.answer.answertype = this.answer.answertype
        this.form.answer.url = this.answer.url
        this.form.answer.content = this.answer.content
        this.form.answer.display = this.answer.display
        this.form.setinactive = (+this.answer.active === 1 ? 0 : 1)
        this.form.setdefault = this.answer.default
        // idk even know
        if (Array.isArray(this.answer.roleids)) {
          this.form.roles = this.answer.roleids
        } else if (this.answer.roleids === null) {
          this.form.roles = []
        } else {
          const str = this.answer.roleids + ''
          this.form.roles = str.split(',')
        }
      }
      if (this.question) {
        this.form.question = this.question
      }
      this.loading = false
      this.$updateloading(-1)
    },

    saveAnswer () {
      this.loading = true
      this.$updateloading(1)
      const formData = new FormData()

      // Append form
      formData.append('id', this.form.id)
      formData.append('document_id', this.form.answer.document_id)
      formData.append('content', this.form.answer.content)
      formData.append('display', this.form.answer.display)
      formData.append('setdefault', this.form.setdefault)
      formData.append('setinactive', this.form.setinactive)
      if (this.form.roles && this.form.roles.length) {
        this.form.roles.forEach((r) => {
          r = r + ''
          if ((typeof r === 'string' || r instanceof String) && r.search(',') === -1) {
            formData.append('roles[]', r)
          }
        })
      }
      formData.append('questionid', this.form.question.id)
      formData.append('question', this.form.question.question)
      formData.append('questioninactive', this.form.question.setinactive)

      // Append file
      if (this.form.file1 && this.form.file1.length) {
        this.form.file1.forEach((f) => {
          formData.append('files[]', f, f.name)
        })
      }

      // Save
      HelpService.saveAnswer(formData).then((response) => {
        this.$aimNotify.notify(response)
        this.$emit('refresh', response.data.info.helpquestion_id)
      }).catch((err) => {
        this.$aimNotify.error(err.response)
        console.dir(err)
      }).finally(() => {
        this.$updateloading(-1)
        this.loading = false
      })
    }
  }

}
</script>
<style scoped>
.view-img {
  border: 1px solid black;
  width: 200px;
}
.view-vid {
  width: 200px !important;
  height: 200px !important;
}
.view-vid-wrapper {
  height: 200px !important;
}
</style>
