import axios from 'axios'

const HelpService = {
  async checkPermission () {
    return axios({
      url: axios.defaults.baseURL + 'help/checkpermission/',
      method: 'GET'
      // data: JSON.stringify({ data: data })
    })
  },

  async getDefaultHelp (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/init/',
      method: 'GET',
      data: JSON.stringify({ data: data })
    })
  },

  async search (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/search/',
      method: 'POST',
      data: JSON.stringify({ data: data })
    })
  },

  async loadAdminInfo (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/admininit/',
      method: 'GET',
      data: JSON.stringify({ data: data })
    })
  },

  async searchAdmin (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/searchadmin/',
      method: 'POST',
      data: JSON.stringify({ data: data })
    })
  },

  async saveQuestion (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/savequestion',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async removeQuestion (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/removequestion',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async loadAnswerModal () {
    return axios({
      url: axios.defaults.baseURL + 'help/loadanswermodal/',
      method: 'GET'
    })
  },

  async loadAnswers (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/loadanswers/',
      method: 'POST',
      data: data
    })
  },

  async saveAnswer (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/saveanswer',
      method: 'POST',
      data: data
    })
  },

  async removeAnswer (data) {
    return axios({
      url: axios.defaults.baseURL + 'help/removeanswer',
      method: 'POST',
      data: JSON.stringify(data)
    })
  }

}

export default HelpService
