<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" md="6" lg="4" xl="3" class="text-md-left text-center text--white">
        <span class="h4">Help Admin</span>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        offset-lg="4"
        xl="3"
        offset-xl="6"
        class="text-right"
      >
        <HelpQuestionModal id="addmodal" @refresh="init()" :roles="roles"></HelpQuestionModal>
      </b-col>
    </b-row>
    <b-card class="mt-4">
      <b-row>
        <b-col cols="12">
          <b-form v-on:submit.prevent="search()" action="" ref="helpForm">
            <b-row>
              <b-col cols="4" class="text-left">
                <b-form-group id="keywords-group" label="Keywords:">
                  <b-form-input
                    id="keywords"
                    v-model="form.keywords"
                    placeholder="Question or Answer text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4" class="text-left">
                <b-form-group id="visible-group" label="Visible To:">
                  <b-form-checkbox-group
                    id="visible-checkbox-group"
                    v-model="form.roles"
                    name="flavour-2"
                  >
                    <b-form-checkbox
                      v-for="(role, idx) in roles"
                      :key="idx"
                      :value="role.id"
                      >{{ role.name }}</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col cols="4" class="text-left">
                <b-form-group id="answertype-group" label="Answer Type:">
                  <b-form-checkbox-group
                    id="answertype-checkbox-group"
                    v-model="form.types"
                    name="flavour-2"
                  >
                    <b-form-checkbox value="text">Text</b-form-checkbox>
                    <b-form-checkbox value="image">Screenshots</b-form-checkbox>
                    <b-form-checkbox value="video">Videos</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4" class="text-left">
                <b-form-checkbox
                  id="includeinactive"
                  v-model="form.questioninactive"
                  name="includeinactive"
                  :value="true"
                  :unchecked-value="false"
                >
                  Include Inactive Questions
                </b-form-checkbox>
                <b-form-checkbox
                  id="defaultonly"
                  v-model="form.defaultonly"
                  name="defaultonly"
                  :value="true"
                  :unchecked-value="false"
                >
                  Default Answers Only
                </b-form-checkbox>
              </b-col>
              <b-col cols="4" class="text-left"></b-col>
              <b-col cols="4" class="text-left">
                <b-row>
                  <b-col cols="6">
                    <b-button block variant="warning" @click="reset()"
                      >Reset</b-button
                    >
                  </b-col>
                  <b-col cols="6">
                    <b-button block variant="primary" @click="search()"
                      >Search</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="12">
          <b-row>
            <table class="table table-striped">
              <thead>
                <th>ID</th>
                <th class="text-left">Question</th>
                <th class="text-right">Answer Count</th>
                <th class="text-left">Visible To</th>
                <th>Active</th>
                <th></th>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in searchresults" :key="idx">
                  <td>{{ row.id }}</td>
                  <td class="text-left">{{ row.question }}</td>
                  <td class="text-right">{{ row.answercount }}</td>
                  <td class="text-left">{{ row.rolenames }}</td>
                  <td>{{ row.active }}</td>
                  <td class="text-right">
                    <b-button-group>
                      <HelpQuestionModal
                        id="editmodal"
                        @refresh="init()"
                        :question="row"
                        :roles="roles"
                        edit
                      ></HelpQuestionModal>
                      <b-button
                        variant="danger"
                        title="Remove Question"
                        @click="removeQuestion(row)"
                        ><b-icon icon="x-square"></b-icon
                      ></b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
// import UserService from '@/services/UserService'
import HelpService from '@/services/HelpService'
import HelpQuestionModal from '@/components/admin/HelpQuestionModal'

export default {
  data () {
    return {
      form: {
        keywords: null,
        roles: [],
        types: [],
        questioninactive: false,
        defaultonly: false
      },
      roles: [],
      searchresults: [],
      loading: false
    }
  },

  components: {
    HelpQuestionModal
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      this.$updateloading(1)
      HelpService.checkPermission().then((response) => {
        this.loadRoles()
        this.search()
      }).catch((error) => {
        console.dir(error)
        this.$aimNotify.error(error.response)
        window.location.href = '/help'
      }).finally(() => {
        this.$updateloading(-1)
        this.loading = false
      })
    },

    loadRoles () {
      this.loading = true
      this.$updateloading(1)
      HelpService.loadAdminInfo().then((response) => {
        this.roles = response.data.info.roles
      }).catch((error) => {
        console.dir(error)
        this.$aimNotify.error(error.response)
      }).finally(() => {
        this.$updateloading(-1)
        this.loading = false
      })
    },

    setModalInfo (row) {
      this.modalinfo = row
    },

    search () {
      this.loading = true
      this.$updateloading(1)
      HelpService.searchAdmin(this.form).then(
        (response) => {
          this.searchresults = response.data.info
          // this.$aimNotify.notify(response)
        }
      ).finally(() => {
        this.$updateloading(-1)
        this.loading = false
      })
    },

    reset () {
      this.form.keywords = null
      this.form.roles = []
      this.form.types = []
      this.form.questioninactive = false
      this.form.defaultonly = false
    },

    removeQuestion (question) {
      this.$bvModal.msgBoxConfirm('Are you sure you wish to remove this question?').then(
        val => {
          if (val) {
            this.loading = true
            this.$updateloading(-1)
            HelpService.removeQuestion(question).then(
              (response) => {
                this.search()
                this.$aimNotify.notify(response)
              }
            ).finally(() => {
              this.$updateloading(-1)
              this.loading = false
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.search-answer-link {
  display: block;
  color: white;
}
.default-answer-link {
  display: block;
  color: white;
  margin: 5px 0 5px 0;
}
</style>
